import AnalyticsEvent from './analytics-event';
import type { SectionType as DiscoverySectionType } from '@bc/discovery';
import type { IdPosition } from '@bc/shared';
import { BannerName } from '@/types/banner';

export enum SectionType {
  BrandShipping = 'brand_shipping',
  BrandValidation = 'brand_validation',
  PaymentMethodsLoaded = 'CheckoutPaymentMethodsLoadedEvent',
  CheckoutDisplay = 'checkout_content_display_event',
  CartContentDisplay = 'cart_content_display_event',
  NativeAds = 'native_ads',
  RecoCarousel = 'reco_carousel',
  // Fulfillment section types
  BrandFulfillment = 'brand_fulfillment',
  ReplenishmentDetails = 'replenishment_details',
  OfferCarousel = 'offer_carousel',
  HpBanner = 'hp_banner',
}

export enum SectionItemType {
  Product = 'product',
  Brand = 'brand',
  NativeAds = 'native_ads',
}
export class SectionCreatedEvent extends AnalyticsEvent {
  public readonly name = 'Section Created';

  constructor(
    public readonly properties: {
      section_type?: SectionType | DiscoverySectionType;
      page_number?: number;
      itemtype?: string;
      value_proposition?: string;
      value_proposition_id?: number;
      id_product?: IdPosition[];
      id_brand?: IdPosition[];
      id_section?: string;
      filters?: unknown[];
      banner_name?: BannerName[] | string;
      [key: string]: unknown;
    }
  ) {
    super();

    if (!('nb_tiles' in this.properties)) {
      const productCount = this.properties.id_product?.length ?? 0;
      const brandCount = this.properties.id_brand?.length ?? 0;

      this.properties.nb_tiles = productCount + brandCount;
    }
  }
}
