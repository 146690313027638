import { ComputedRef } from 'vue';
import { isEnabled } from '@/services/features';
import type { MultipleQueriesQuery, MultipleQueriesResponse } from '@algolia/client-search';
import { getGlobalEvents } from '@/services/api/business-events';
import { http } from '@/services/api/http';
import { SearchApiClient, Config, Context, SnippetTemplate, facetRef } from '@bc/discovery/domain/catalog';

function amendFacetsStats<TResult>(response: MultipleQueriesResponse<TResult>) {
  response.results.forEach((result) => {
    if ('facets_stats' in result) {
      Object.entries(result.facets_stats ?? {}).forEach(([facetName, facetStat]) => {
        // @ts-expect-error This is a hack to add the stats to the facet. AisRangeInput requires it.
        // https://github.com/algolia/instantsearch/blob/7b06c47ec9d901b5c2544c0edd793ee63208cfcb/packages/instantsearch.js/src/connectors/range/connectRange.ts#L354
        result.facets[facetName] = { stats: facetStat };
      });
    }
  });

  return response;
}

async function getEventsWithLiveDiscounts() {
  const globalEvents = await getGlobalEvents();
  return globalEvents.filter((event) => event.with_discount_live);
}

async function getTilesEvent() {
  const globalEvents = await getGlobalEvents();
  return globalEvents.filter((event) => event?.external_cms_content?.['catalogue-tile']).filter(Boolean);
}

async function amendDiscountFacets<TResult>(response: MultipleQueriesResponse<TResult>) {
  const eventsWithLiveDiscounts = await getEventsWithLiveDiscounts();

  if (!eventsWithLiveDiscounts.length) {
    return response;
  }

  return {
    results: response.results.map((result) => {
      return {
        ...result,
        facets: {
          ...('facets' in result ? result.facets : {}),
          [facetRef.discount]: {
            '1_10': Number.POSITIVE_INFINITY,
            '11_20': Number.POSITIVE_INFINITY,
            '21_30': Number.POSITIVE_INFINITY,
            '31_40': Number.POSITIVE_INFINITY,
            '41_*': Number.POSITIVE_INFINITY,
          },
        },
      };
    }),
  };
}

export const removeAnkorstorePlusFacets =
  (config: Config, userCountry: string) =>
  <TResult>(results: MultipleQueriesResponse<TResult>) => {
    if (!(isEnabled('RET-2591') || !userCountry || userCountry === 'FR')) {
      return results;
    }

    const isProductSearch = config.template === SnippetTemplate.PRODUCTS;

    if (isProductSearch) {
      results.results.forEach((element) => {
        if ('facets' in element && element.facets['brand.aks_plus']) {
          delete element.facets['brand.aks_plus']['delivering_in_48h'];
        }
      });
    } else {
      results.results.forEach((element) => {
        if ('facets' in element && element.facets['aks_plus']) {
          delete element.facets['aks_plus']['delivering_in_48h'];
        }
      });
    }

    return results;
  };

const isFacetFilterArray = (facetFilters: MultipleQueriesQuery['params']['facetFilters']): facetFilters is string[][] =>
  Array.isArray(facetFilters) && facetFilters.every((filter) => Array.isArray(filter));

const transformDiscountFilter = (config: Config) => async (requests: readonly MultipleQueriesQuery[]) => {
  const eventsWithLiveDiscounts = await getEventsWithLiveDiscounts();

  return requests.map((request) => {
    const isBrandIndex = config?.template === SnippetTemplate.BRANDS;
    if (isFacetFilterArray(request.params.facetFilters)) {
      const facetFilters = request.params.facetFilters.map((filters) =>
        filters.flatMap((filter) => {
          const [facetName, facetValue] = filter.split(':');

          if (facetName === facetRef.discount && eventsWithLiveDiscounts.length) {
            return eventsWithLiveDiscounts.map((event) =>
              isBrandIndex
                ? `business_events.${event.business_event_id}.discount.range:${facetValue}`
                : `brand.business_events.${event.business_event_id}.discount.range:${facetValue}`
            );
          }

          return filter;
        })
      );

      return {
        ...request,
        params: {
          ...request.params,
          facetFilters,
        },
      };
    } else {
      return request;
    }
  });
};

const insertAdsParams =
  (config: Config, catalogTilesPerRow: ComputedRef<number>) => async (requests: Readonly<MultipleQueriesQuery[]>) => {
    if (isAdsEnabled(config?.context, catalogTilesPerRow?.value)) {
      return requests.map((request) => ({
        ...request,
        params: {
          ...request.params,
          'ads[rowCount]': catalogTilesPerRow.value,
          'ads[limit]': 12,
        },
      }));
    }

    return requests;
  };
const adjustHitsPerPageForEventTiles = (config: Config) => async (requests: Readonly<MultipleQueriesQuery[]>) => {
  if (config?.eventTile) {
    const tiles = await getTilesEvent();
    return requests.map((request) => ({
      ...request,
      params: {
        ...request.params,
        hitsPerPage: Math.max(request.params.hitsPerPage - tiles.length, 0),
      },
    }));
  }

  return requests;
};
const insertEventTiles = async <TResult>(response: Readonly<MultipleQueriesResponse<TResult>>) => {
  const tiles = await getTilesEvent();
  const firstResult = response.results[0];

  if ('hits' in firstResult && firstResult.hits.length && tiles.length) {
    // On subsequent reqeusts, the previously inserted event tiles are still present. We need to remove them before inserting them again.
    const firstResultHits = firstResult.hits.filter((hit) => !('external_cms_content' in hit));

    tiles.forEach((element) => {
      firstResultHits.splice(
        element.external_cms_content['catalogue-tile'][0].body[0].primary.position - 1,
        0,
        element as unknown as (typeof firstResultHits)[0]
      );
    });

    firstResult.hits = firstResultHits;
  }

  return response;
};

export class CatalogSearchApiClient extends SearchApiClient {
  constructor(config: Config, catalogTilesPerRow: ComputedRef<number>, userCountry: string) {
    super(http(), window.location.host);

    this.onRequest(adjustHitsPerPageForEventTiles(config))
      .onRequest(insertAdsParams(config, catalogTilesPerRow))
      .onRequest(transformDiscountFilter(config));

    this.onResponse(amendFacetsStats)
      .onResponse(amendDiscountFacets)
      .onResponse(insertEventTiles)
      .onResponse(removeAnkorstorePlusFacets(config, userCountry));
  }
}

const isAdsEnabled = (context: Context, catalogTilesPerRow: number) => {
  return [Context.CATEGORY_PRODUCTS, Context.SEARCH].includes(context) && isEnabled('RET-2772') && catalogTilesPerRow;
};
