import { TailwindConfig } from '@ankorstore/design-system';
import { GlobalMetaTagName } from '@/services/initial-state';
import { getInjectedContent } from './injected-content';

const theme = TailwindConfig.theme;
const getFlags = (): Record<string, string> => getInjectedContent<Record<string, string>>(GlobalMetaTagName.Features) ?? {};

export const getExperiments = function (): Record<string, string> {
  return getInjectedContent<Record<string, string>>(GlobalMetaTagName.Experiments) ?? {};
};

export const getBrowserId = function (): string | null {
  return getInjectedContent<string>(GlobalMetaTagName.BrowserId) ?? null;
};

export const getVariant = function (featureName: FeatureFlag | string): string {
  const flags = getFlags();
  if (Object.prototype.hasOwnProperty.call(flags, featureName)) {
    return flags[featureName];
  }
  return 'off';
};

export const isEnabled = function (featureName: string): boolean {
  const variant = getVariant(featureName);
  return 'off' !== variant && 'ineligible' !== variant;
};

export const screens = Object.keys(theme.screens).reduce((acc, key) => {
  acc[key] = parseInt(theme.screens[key], 10);
  return acc;
}, {});

export const appTheme = {
  screens,
  fonts: theme.fontFamily,
  colors: theme.colors,
};

export const configureVue = function (app) {
  app.config.globalProperties.$experiments = getExperiments;
  app.config.globalProperties.$isEnabled = isEnabled;
  app.config.globalProperties.$variant = getVariant;
  app.config.globalProperties.$theme = appTheme;
};

export enum FeatureFlag {
  Ankorstart = 'retailer_signup_ankorstart',
  CatalogueExport = 'catalog_export',
  CartnCheckoutRelease3_1 = 'cartcheckout_release_3-1',
  CheckoutSilentValidation = 'checkout_silent_validation',
  FilterBar = 'filter_bar_v0',
  FiltersModal = 'filters_modal_v0',
  InternationalShippingMessages = 'international_shipping_messages',
  LeadTime = 'leadtime',
  LegacyMapsWidget = 'legacy_maps_widget',
  LocalisedSearch = 'localised_search',
  MandatoryEori = 'mandatory_eori',
  PickupCancellation = 'pickup_cancellation',
  productsRecommendations = 'RET-1337',
  ProductVariants = 'product_variants',
  Ravelin = 'ravelin',
  RecommendationsRedirect = 'project_path_to_moria',
  RegisterGeodataVerification = 'register_geodata_verification',
  RegistrationFunnelMockedData = 'RCSGT-611',
  RegistrationFunnelRevamp = 'RCSGT-453',
  RegistrationRename = 'registration_rename',
  SignUpAutoOpen = 'signup_auto_open',
  SignUpPersonalize = 'sign_up_personalize',
  SignUpPromocode = 'sign_up_promocode',
  SuggestionsService = 'suggestions_service',
  SumupOffer = 'sumup_offer',
  WelcomeDiscountOffer = 'welcome_discount_offer',
  NonFrBrandJoiningFF = 'non_fr_brand_join_ff',
  FulfillmentReplenishmentViewNewWarehouseWarning = 'fulfillment_replenishment_view_new_warehouse_warning',
  Auth2FA = 'auth.multi_factor_authentication',
  RequiredInformation = 'required_information',
  EnableNewBusinessModel = 'enable_new_business_model',
  NafoB2C = 'nafo_b2c',
  PromisedDeliveryDate = 'promised_delivery_date',
  CartBrandRecommendationsCarousel = 'cart_brand_recommendations_carousel',
  CartBrandRecommendations = 'cart_brand_recommendations',
  NewCreateCampaigns = 'new_create_campaigns',
  OrderDetailShippingClarity = 'order_detail_shipping_clarity',
  HolidayMode = 'holiday_mode',
  ProductPreorderInfoPopup = 'OXP-1932',
  RepeatOfferColumn = 'repeat_offer_column',
  NewPreorderPaymentSummary = 'OXP-2032',
}
