<template>
  <div class="ds-mb-2">
    <div
      class="wholesale-price--section ds-block"
      :class="NativeAdClickOrigin.WholesalePrice"
    >
      <template v-if="canGetWholesalePrice">
        <div class="price-wholesale connected">
          <div
            data-testid="wholesale-price"
            class="wholesale-price ds-flex ds-font-bold ds-mr-1 ds-text-base ds-items-baseline"
            :class="[largeText ? 'ds-text-2xl' : '', context == 'tile' ? 'ds-gap-1' : 'ds-gap-2']"
          >
            <span
              data-testid="discountedPrice"
              :class="[priceColorClass]"
            >
              {{ formattedWholesalePrice }}
            </span>
            <span
              v-if="hasDiscount"
              data-testid="original-price"
              class="ds-text-base ds-text-primary ds-font-normal ds-line-through"
              :class="largeText ? 'ds-text-2xl' : ''"
            >
              {{ formattedOriginalWholesalePrice }}
            </span>

            <ReportPrice
              v-if="showReportPrice"
              :brand-id="item.brand.id"
              :brand-name="item.brand.name"
              :brand-image="item.brand.images.rounded"
              :product-variant-id="variantIdSelected"
            />
          </div>
        </div>

        <div
          data-testid="unit-multiplier"
          class="ds-text-neutral-700 ds-text-xs"
          :class="[largeText ? 'ds-text-sm' : '', boldText ? 'ds-font-bold' : 'ds-font-base']"
        >
          <span>
            {{ $tc('Case of {count}', item.unit_multiplier) }}
          </span>
          <span
            v-if="largeText"
            class="price-retail ds-ml-1"
          >
            {{ $t('SRP') }}&nbsp;{{ formattedRetailPrice }}
          </span>
        </div>
      </template>

      <template v-else-if="isRetailer">
        <SeePriceCta>
          <template #default="{ openModal }">
            <span
              class="price-wholesale ds-flex ds-items-center"
              @click.stop.prevent="openModal"
            >
              <mark class="ds-bg-transparent">
                <div class="ds-flex flex-row ds-items-baseline ds-text-info ds-font-bold">
                  <AkIcon
                    ref="iconLock"
                    symbol="lock"
                  />
                  <span
                    data-testid="price-link"
                    :class="{
                      'ds-cursor-pointer': true,
                      'ds-break-all': isGerman,
                    }"
                  >
                    {{ $t('See the wholesale Price') }}
                  </span>
                </div>
              </mark>
            </span>
          </template>
        </SeePriceCta>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType, watchEffect, computed } from 'vue';

import { PriceBreakdown, DiscountType } from '@/types/product';
import { AkIcon } from '@ankorstore/design-system';
import { BPGContext } from '@/types/badges/bpg/bpg';
import ReportPrice from '@/modules/shared/best-price-guarantee/components/report-price.vue';
import { isEnabled } from '@/services/features';
import ProductDraft from '@/types/account/account-products/catalog-integration/product-integration';
import { NativeAdClickOrigin } from '@bc/advertisement';
import usePrice from '@/composables/use-price';
import { SeePriceCta } from '@bc/shared';
import { getLang } from '@/utilities/lang';
import Product from '@/types/product';
import { ESProductHit } from '@bc/discovery';

export default defineComponent({
  name: 'WholesalePrice',
  components: {
    AkIcon,
    ReportPrice,
    SeePriceCta,
  },
  props: {
    item: {
      type: Object as PropType<Product | ProductDraft | ESProductHit>,
      required: true,
    },
    priceBreakdown: {
      type: Object as PropType<PriceBreakdown>,
      required: true,
    },
    canGetWholesalePrice: {
      type: Boolean,
      default: false,
    },
    variantIdSelected: {
      type: Number,
      required: false,
      default: null,
    },
    context: {
      type: String as PropType<BPGContext>,
      required: false,
      default: null,
    },
    isBpgCompliant: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
    userIsRetailer: {
      type: Boolean,
      required: true,
    },
    largeText: {
      type: Boolean,
      default: false,
    },
    sizeInfoIcon: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'sm',
    },
    boldText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { getConvertedPrice, formatPrice } = usePrice();
    const formattedOriginalWholesalePrice = ref('');
    const formattedWholesalePrice = ref('');
    const formattedRetailPrice = ref('');
    watchEffect(async () => {
      const [convertedOriginalPrice, convertedWholesalePrice, convertedRetailPrice] = await Promise.all([
        getConvertedPrice(props.priceBreakdown.originalPrice),
        getConvertedPrice(props.priceBreakdown.wholesalePrice),
        getConvertedPrice(props.priceBreakdown.retailPrice),
      ]);
      formattedOriginalWholesalePrice.value = formatPrice(convertedOriginalPrice);
      formattedWholesalePrice.value = formatPrice(convertedWholesalePrice);
      formattedRetailPrice.value = formatPrice(convertedRetailPrice);
    });

    const isRetailer = computed(() => !props.user || props.userIsRetailer);
    const hasReportButtonBpg = computed(() => props.isBpgCompliant && props.context !== BPGContext.tile);
    const hasDiscount = computed(() => props.priceBreakdown.originalPrice.amount !== props.priceBreakdown.finalPrice.amount);
    const priceColorClass = computed(() => {
      if (hasDiscount.value) {
        return props.item.discount_type === DiscountType.BRAND ? 'price' : 'price--discounted';
      }

      return undefined;
    });
    const showReportPrice = computed(
      () => isEnabled('pricing.bxp-1491.store.report') && hasReportButtonBpg.value && props.variantIdSelected !== null
    );
    const isGerman = computed(() => getLang() === 'de');

    return {
      formattedOriginalWholesalePrice,
      formattedWholesalePrice,
      formattedRetailPrice,
      NativeAdClickOrigin,
      isRetailer,
      hasReportButtonBpg,
      hasDiscount,
      priceColorClass,
      showReportPrice,
      isGerman,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.wholesale-price--section {
  .wholesale-price {
    .v-popper {
      @apply ds-inline;
    }
  }

  .price-retail {
    &::before {
      @apply ds-mr-1;
      content: '·';
    }
  }

  .information-icon::before {
    @apply ds-text-neutral-600;
  }
}

.price {
  @apply ds-text-primary;
  &--discounted {
    @apply ds-text-discount;
  }
}
</style>
