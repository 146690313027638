import { Sort } from '@/types/sorting';
import { SortColumns } from '../types/contacts.types';

export const ENABLED_FILTERS: string[] = [
  'ordering.lastOrderedAt',
  'ordering.totalSpent.amount',
  'hasProductsInCart',
  'commercialTerms.hasBrandDiscount',
  'commercialTerms.hasRepeatOffer',
  'commercialTerms.serviceFees',
  // hasFreeShippingForBrand facet is a virtual helper facet, on the backend it is calculating the correct value based on `commercialTerms.freeShippingForBrandUntil` facet and current date
  'hasFreeShippingForBrand',
  'ordering.liftEligible',
  'registered',
  'retailer.contact.country',
  'retailer.shopType',
  'retailer.aksPlus.active',
  // language facet is a virtual helper facet, on the backend it is calculating the correct language based on `contact.language` facet and `retailer.language` facet
  'language',
  'communication.emailing.subscriptionStatus',
];

export const DEFAULT_SORTING = { [SortColumns.total_spent]: Sort.Desc };

export const FACETS: string[] = ['segment'];
